<template>
  <v-container>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : editorWidth">
        <v-card
          :width="contentWidth"
          elevation="0"
          outlined
          class="mx-auto pt-4"
        >
          <v-row class="align-center mb-0">
            <v-col lg="7" xl="7" class="overflow-x-hidden">
              <v-card-title class="mr-0 py-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <input
                      v-bind="attrs"
                      v-on="on"
                      v-model="defaultVacationTemplate.name"
                      :size="defaultVacationTemplate.name.length"
                      style="width: 98%"
                      required
                      data-testid="input-name"
                      placeholder="Nome do modelo"
                      :class="`${!validNameRule ? 'error-input' : ''}`"
                    />
                  </template>
                  <span>{{
                    isEditingVacationTemplate ? "Renomear" : "Nome do modelo"
                  }}</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="template-config ma-0 pa-0">
                <span
                  v-if="defaultVacationTemplate.name.length > maxNameSize"
                  class="error--text py-0 px-4 caption"
                  >O título não pode ultrapassar 50 caracteres.</span
                >
                <v-card-subtitle class="mb-0 pr-0 mr-0 py-0 mt-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        v-bind="attrs"
                        v-on="on"
                        v-model="defaultVacationTemplate.description"
                        placeholder="Descrição"
                        data-testid="input-description"
                        :size="defaultVacationTemplate.description.length - 5"
                        :class="`${!descriptionRule ? 'error-input' : ''}`"
                      />
                    </template>
                    <span>{{
                      isEditingVacationTemplate
                        ? "Alterar descrição"
                        : "descrição"
                    }}</span>
                  </v-tooltip>
                </v-card-subtitle>
                <span
                  v-if="!descriptionRule"
                  class="error--text py-0 px-4 caption"
                  >A descrição não pode ultrapassar de 60 caracteres.</span
                >
              </v-card-text>
            </v-col>

            <v-col md="12" lg="5" xl="5" class="pr-6 pt-0 mt-0">
              <v-row class="mt-0 py-0 ma-0 align-start">
                <v-col v-if="$vuetify.breakpoint.xlAndUp">
                  <v-spacer />
                </v-col>

                <!-- VIEW BUTTON -->
                <v-col v-if="previewWidth < 1">
                  <v-btn
                    block
                    color="accent"
                    class="text-none text-body-1 font-weight-bold pl-4 pr-5 mr-4"
                    dark
                    outlined
                    large
                    @click="showPreview = true"
                  >
                    <v-icon v-text="'ph-eye-slash'" left />
                    Visualizar
                  </v-btn>
                </v-col>

                <!-- SAVE BUTTON -->
                <v-col class="mx-0 px-0">
                  <v-menu open-on-hover bottom left offset-y class="mx-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        block
                        color="accent"
                        class="text-none text-body-1 font-weight-bold px-6"
                        large
                        :dark="requiredRulesToSaveTemplate"
                        :loading="loading"
                        :disabled="!requiredRulesToSaveTemplate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon v-text="'ph-fill ph-floppy-disk-back'" left />
                        Salvar
                      </v-btn>
                    </template>

                    <v-list
                      nav
                      width="350"
                      class="font-weight-medium"
                      :disabled="loading"
                    >
                      <v-list-item @click="execute">
                        <v-list-item-icon class="mr-4">
                          <v-icon v-text="'ph-check-circle'" color="accent" />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Apenas salvar</v-list-item-title>
                          <v-list-item-subtitle
                            class="text-wrap font-weight-regular mt-1 caption"
                          >
                            Salvar as alterações feitas no modelo sem reaplicar
                            para os usuários que o utilizam
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="saveAndApply">
                        <v-list-item-icon class="mr-4">
                          <v-icon
                            v-text="'ph-clock-clockwise'"
                            color="accent"
                          />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Salvar e reaplicar</v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text-wrap font-weight-regular mt-1 caption"
                          >
                            Salvar as alterações e reaplicar modelo atualizado
                            para os usuários que o utilizam
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <span
                v-if="isEditingVacationTemplate"
                :class="`float-right caption grey--text text--darken-1 font-italic mt-2 ${
                  $vuetify.breakpoint.mobile ? 'mr-4' : 'mr-2'
                }`"
              >
                Última atualização:
                {{
                  formatDateAndHour(defaultVacationTemplate.updated_at, true)
                }}
              </span>
            </v-col>
            <v-row class="px-5">
              <v-col class="pr-2">
                <HelpBanner
                  title="Dúvida sobre criação de mensagem de férias?"
                />
              </v-col>
            </v-row>
          </v-row>

          <!-- E-MAIL SUBJECT -->
          <v-row class="pr-3 pl-4 mb-4">
            <v-col cols="12" class="pb-0 px-2">
              <v-text-field
                v-model="defaultVacationTemplate.response_subject"
                color="accent"
                :rules="subject"
                data-testid="input-email-subject"
                label="Assunto do e-mail*"
                required
                outlined
                hide-details
                dense
              >
                <template v-slot:label>
                  Assunto do e-mail<span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <div id="messageEditor" class="my-4" transition="fade-transition">
            <NewsTip
              position="top"
              name="cn-9539"
              :title="$t('understood.cn_9539.title')"
              :content="$t('understood.cn_9539.content')"
              :start-date="SPRINT_120_2ND_WEEK_START"
              next-tip-name="cn-9543"
              ignore-client-rule
            >
              <Editor
                :html="templateHtml"
                :disabled="disableEditor"
                :key="templateHtml"
                @update="editorHtml = $event"
                isVacation
              />
            </NewsTip>
          </div>
          <span
            :class="`caption ml-3 grey--text text--darken-1 font-italic ${
              exceededMaxTemplateContentSize
                ? 'error--text font-weight-bold'
                : ''
            }`"
          >
            {{ editorHtml.length }}/{{ templateLength }}
            {{
              exceededMaxTemplateContentSize
                ? "limite de caracteres excedido"
                : "caracteres"
            }}
          </span>
          <v-card-actions class="ma-0 pa-0">
            <v-switch
              v-if="isConecta"
              :disabled="!template_key"
              class="py-0 my-0 ml-2 pl-2"
              :value.sync="isDefault"
              @change="toggleCustomDefaultTemplate"
              :true-value="true"
              :false-value="false"
              :loading="loading"
              :label="`Modelo padrão`"
              color="accent"
              inset
              id="v-step-9"
              hide-details
            ></v-switch>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  data-testid="delete-btn"
                  :disabled="!template_key || isDefault"
                  @click="
                    (dialogDeleteTemplate = true),
                      (template_to_delete = template_key)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :class="`my-auto text-none font-weight-medium`"
                  id="v-step-10"
                  icon
                  large
                  color="grey darken-1"
                >
                  <v-icon v-text="'ph-trash'" />
                </v-btn>
              </template>
              <span>Excluir modelo</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="previewWidth > 0"
        :cols="$vuetify.breakpoint.smAndDown ? 12 : previewWidth"
        class="mx-auto mb-7"
      >
        <v-card outlined :width="contentWidth" class="mx-auto">
          <v-card-actions
            class="align-center text-subtitle-1 text-body-1 font-weight-medium py-3 px-4"
          >
            Pré-visualização
            <!-- TODO: Inserir um autocomplete para trocar as informações para serem visualizadas de acordo com o usuário -->
          </v-card-actions>
          <v-divider />
          <v-card-text class="text-none" v-if="editorHtml.length">
            <TemplatePreview
              :class="editorWidth == 6 ? 'template-scale' : ''"
              :html="editorHtml"
              :key="htmlPreview"
              signature-only
            />
          </v-card-text>
          <v-card-subtitle v-else>
            Nenhuma visualização disponível
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOG: CONFIRMAR EXCLUSÃO DE UM TEMPLATE -->
    <v-dialog v-model="dialogDeleteTemplate" width="500">
      <v-card>
        <v-card-title primary-title>Confirmar exclusão</v-card-title>
        <v-card-text>Você realmente deseja excluir o template? </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogDeleteTemplate = false"
            >Cancelar</v-btn
          >
          <v-btn text @click="exclude(template_to_delete)">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-----CONFIRMAR SAIR SEM SALVAR----->
    <HeaderDialog
      :show="showConfirmChanges"
      title="Você tem alterações não salvas"
      :showCancel="true"
      @close="
        showConfirmChanges = false;
        next(false);
      "
      @action="
        showConfirmChanges = false;
        next(true);
      "
      actionText="Sair sem salvar"
      closeText="Continuar editando"
      :disabledAction="false"
    >
      <template v-slot:body>
        <p class="mt-3">Deseja sair do editor sem salvar as alterações?</p>
      </template>
    </HeaderDialog>

    <!-- VACATION TEMPLATE PREVIEW -->
    <DialogPreview
      :show="showPreview"
      @close="showPreview = false"
      :html="htmlPreview"
      :key="Math.random()"
      :subject="defaultVacationTemplate.response_subject"
      isVacationPreview
    />

    <!-- APPLY VACATION DIALOG -->
    <HeaderDialog
      v-if="showVacationDialog"
      id="add_vacation_message_for_multiple"
      color="primary"
      title="Deseja aplicar a mensagem de férias?"
      :show="showVacationDialog"
      width="900"
      :disabledAction="!validVacationData || !dateRangeText"
      :persistent="false"
      @close="closeVacationDialog"
      @action="batchUpdateVacation"
      :loading="vacation.loading"
    >
      <template v-slot:body>
        <div>
          <v-card-text class="mx-0 pt-0 px-0">
            Configure uma resposta automática de férias para avisar
            automaticamente que o usuário não poderá responder às mensagens de
            imediato. A resposta automática de férias, com as informações que
            você inseriu, será enviada quando o usuário receber um e-mail dentro
            do período determinado.

            <br /><br />Você pode bloquear o acesso dos usuários durante o
            período de férias utilizando o
            <v-btn
              text
              small
              color="primaryControl"
              class="pl-1 pr-0 text-none text-subtitle-2"
              :href="goToControlUsersPage"
              target="_blank"
            >
              <div style="max-width: 20px">
                <Logo
                  product="conecta_control"
                  width="28"
                  no-margins
                  class="mr-2"
                />
              </div>
              Conecta Control
              <v-icon right small v-text="'ph-arrow-square-out'" />
            </v-btn>
          </v-card-text>
          <v-card elevation="0" outlined>
            <v-card-title class="font-weight-bold grey lighten-4 body-1 py-2"
              >Configurar a mensagem de férias</v-card-title
            >
            <v-card-text>
              <v-menu
                v-model="menu.dates"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mt-4"
                    v-model="dateRangeText"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :color="secondaryColor"
                  >
                    <template v-slot:label>
                      Período de férias<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dates" range :color="secondaryColor">
                  <v-spacer></v-spacer>
                  <v-tooltip :disabled="dates.length == 2" top>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          text
                          :color="secondaryColor"
                          :disabled="dates.length < 2"
                          @click="confirmDatesVacation()"
                          >OK</v-btn
                        >
                      </div>
                    </template>
                    <span>Selecione a data de ínicio e fim</span>
                  </v-tooltip>
                </v-date-picker>
              </v-menu>
              <v-autocomplete
                clearable
                open-on-clear
                v-model="currentSelectedUsers"
                :items="users"
                label="Usuários selecionados"
                multiple
                chips
                class="mt-4"
                color="accent"
                dense
                item-text="email"
                item-value="email"
              >
                <template v-slot:selection="{ attrs, item, selected, parent }">
                  <v-chip
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    class="ma-1 link"
                    small
                    :input-value="selected"
                  >
                    <v-avatar left>
                      <v-img
                        :src="
                          item.photo ||
                          item.user.photo ||
                          '@/assets/user-default.png'
                        "
                      />
                    </v-avatar>
                    <span class="pr-2">
                      {{ item.name }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)"
                      v-text="'ph-x-circle'"
                    />
                  </v-chip>
                </template>

                <template v-slot:item="{ item, attrs, on }">
                  <template>
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      active-class="light-blue lighten-5"
                    >
                      <v-list-item-action>
                        <v-checkbox
                          v-model="attrs.inputValue"
                          :color="attrs.inputValue ? 'accent' : 'secondary'"
                        />
                      </v-list-item-action>
                      <v-list-item-avatar size="32">
                        <v-img
                          :src="
                            item.photo ||
                            item.user.photo ||
                            '@/assets/user-default.png'
                          "
                          contain
                        />
                      </v-list-item-avatar>
                      <v-list-item-content class="pl-4">
                        <v-list-item-title
                          :class="
                            attrs.inputValue
                              ? 'accent--text'
                              : 'secondary--text'
                          "
                          v-html="item.name"
                        />
                        <v-list-item-subtitle v-html="item.email" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </div>
      </template>
    </HeaderDialog>
  </v-container>
</template>

<script>
import HelpBanner from "@/components/help/HelpBanner";
import DialogPreview from "@/components/sign/general/DialogPreview";
import Editor from "@/components/sign/general/Editor";
import TemplatePreview from "@/components/sign/general/TemplatePreview";
import { fluxTemplateToPreview } from "@/helpers/services/tagPreview";
import {
  formatDate,
  formatDateAndHour,
  getVacationDates,
} from "@/helpers/services/utils";
import { SPRINT_120_2ND_WEEK_START } from "@/helpers/variables/dates";
import { templateLength } from "@/helpers/variables/general";
import {
  errorMessages,
  snackbarMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { defaultVacationTemplate } from "@/helpers/variables/templates";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "NewVacationTemplate",
  components: { Editor, DialogPreview, TemplatePreview, HelpBanner },
  data: () => ({
    defaultVacationTemplate,
    template_key: "",
    subject: [(val) => (val || "").length > 0 || "Campo obrigatório"],
    templateLength,
    dialogDeleteTemplate: false,
    template_to_delete: null,
    showPreview: false,
    isEditingVacationTemplate: false,
    snackbarMessages,
    disableEditor: false,
    editorHtml: "",
    next: () => {},
    saveChanges: false,
    showConfirmChanges: false,
    showVacationDialog: false,
    vacation: {},
    dates: [],
    menu: {
      dates: null,
    },
    currentSelectedUsers: [],
    SPRINT_120_2ND_WEEK_START,
  }),
  beforeRouteLeave(to, from, next) {
    this.next = next;
    if (
      this.editingTemplate &&
      to.fullPath.includes("/sign/templates/vacation-messages") &&
      this.loading
    ) {
      this.next(true);
    } else if (!this.saveChanges && this.editingTemplate) {
      this.showConfirmChanges = true;
    } else if (this.saveChanges && this.editingTemplate) {
      this.next(true);
    } else {
      this.next(true);
    }
  },
  watch: {
    editorHtml() {
      if (this.editingTemplate && !this.saveChanges) {
        window.addEventListener("beforeunload", this.beforeUnloadHandler);
      } else if (!this.editingTemplate || this.saveChanges) {
        window.removeEventListener("beforeunload", this.beforeUnloadHandler);
      }
    },
  },
  methods: {
    formatDate,
    formatDateAndHour,

    ...mapActions(["createNewVacationTemplate", "changeVacationModelType"]),
    ...mapMutations(["setSnackBar", "setLoading"]),

    beforeUnloadHandler(event) {
      event.preventDefault();
      event.returnValue = "";
    },

    async exclude(template_to_delete) {
      this.setLoading(true);

      const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/vacation/templates/${template_to_delete}`;
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      await this.$axios
        .delete(url, auth)
        .then(() => {
          this.redirectToVacationTemplates();
          this.setSnackBar({
            message: successMessages.delete_template,
            show: true,
          });
        })
        .catch(() => {
          this.setSnackBar({
            color: "error",
            message: errorMessages.delete_template,
            show: true,
          });
        });

      this.dialogDeleteTemplate = false;

      this.setLoading(false);
    },

    setDefaultVacationSettings() {
      const defaultVacationSettings = {
        loading: false,
        settings: {
          enable_auto_reply: false,
          restrict_to_contacts: false,
          restrict_to_domain: false,
          start_time: null,
          end_time: null,
        },
      };
      this.dates = [];
      this.vacation = defaultVacationSettings;
    },

    async saveAndApply() {
      await this.execute();
      this.showVacationDialog = true;
    },

    closeVacationDialog() {
      this.setDefaultVacationSettings();
      this.showVacationDialog = false;
    },

    batchUpdateVacation() {
      this.vacation.loading = true;
      this.vacation.settings.enable_auto_reply = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/vacation/batch:async`;

      const headers = {
        Authorization: this.token,
      };

      const payload = {
        emails: this.currentSelectedUsers,
      };

      payload.vacation_settings = this.vacation.settings;

      if (this.template_key) {
        payload.vacation_template_key = this.template_key;
      }

      this.$axios
        .put(url, payload, { headers })
        .then(() => {
          this.currentSelected = [];
          this.setSnackBar({
            message: successMessages.vacation_message,
            show: true,
          });
        })
        .catch(() => {
          this.setSnackBar({
            message: errorMessages.vacation_message,
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.showVacationDialog = false;
          this.vacation.loading = false;
          this.setDefaultVacationSettings();
        });
    },

    confirmDatesVacation() {
      this.menu.dates = false;
      [this.vacation.settings.start_time, this.vacation.settings.end_time] =
        this.dates.sort();
    },

    redirectToVacationTemplates() {
      this.$router.push({ name: "VacationMessageTemplates" });
    },

    async execute() {
      this.isEditingVacationTemplate
        ? await this.updateVacationTemplate()
        : await this.save();
    },

    setSnackBarConfig(dataCode, message) {
      const snackbarConfig = { show: true };
      if (dataCode || dataCode == undefined) {
        snackbarConfig.message_key = dataCode;
        snackbarConfig.is_key = true;
        snackbarConfig.color = "error";
      } else {
        snackbarConfig.message = message;
      }
      this.setSnackBar(snackbarConfig);
    },

    async save() {
      this.setLoading(true);

      const { name, description, response_subject } =
        this.defaultVacationTemplate;
      const payload = {
        name: name.trim(),
        description,
        response_subject,
        content: this.editorHtml,
        is_html: true,
      };
      await this.createNewVacationTemplate(payload)
        .then((response) => {
          this.setSnackBarConfig(
            false,
            this.snackbarMessages.new_vacation_model
          );
          this.saveChanges = true;
          this.template_key = response.data.key;
          this.$router.push({
            path: `/sign/templates/vacation-message/${response.data.key}`,
          });
          this.isEditingVacationTemplate = true;
        })
        .catch((err) => {
          console.error(err);
          const { data } = err.response;
          this.setSnackBarConfig(data.code);
          this.redirectToVacationTemplates();
        })
        .finally(() => {
          this.setLoading(false);
        });
    },

    async getVacationTemplateByKey() {
      this.setLoading(true);
      await this.$axios
        .get(this.getOrUpdateUrl, this.auth)
        .then(({ data }) => {
          this.defaultVacationTemplate = data;
          this.editorHtml = data.content;
        })
        .catch((err) => {
          console.error(err);
          const { data } = err.response;
          this.setSnackBarConfig(data.code);
          this.redirectToVacationTemplates();
        })
        .finally(() => {
          this.setLoading(false);
        });
    },

    async updateVacationTemplate() {
      this.setLoading(true);
      const { name, description, response_subject, is_default } =
        this.defaultVacationTemplate;
      const payload = {
        name,
        description,
        response_subject,
        content: this.editorHtml,
        is_default,
      };
      await this.$axios
        .put(this.getOrUpdateUrl, payload, this.auth)
        .then(() => {
          this.setSnackBarConfig(false, this.snackbarMessages.saves_changes);
          this.getVacationTemplateByKey();
        })
        .catch((err) => {
          console.error(err);
          const { data } = err.response;
          this.setSnackBarConfig(data.code);
        })
        .finally(() => {
          this.setLoading(false);
          this.saveChanges = true;
        });
    },

    async toggleCustomDefaultTemplate() {
      this.setLoading(true);
      await this.changeVacationModelType(this.template_key)
        .then(({ data }) => {
          this.defaultVacationTemplate = data;
          this.setSnackBarConfig(
            false,
            `Modelo salvo e tornado ${
              this.isDefault ? "padrão" : "personalizado"
            }.`
          );
          this.redirectToVacationTemplates();
        })
        .catch((err) => {
          console.error(err);
          const { data } = err.response;
          this.setSnackBarConfig(data.code);
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
  },

  computed: {
    ...mapGetters([
      "vacationEditorLayout",
      "token",
      "isConecta",
      "contentWidth",
      "secondaryColor",
      "loading",
      "users",
    ]),

    htmlPreview() {
      return this.editorHtml || this.templateHtml;
    },

    editorWidth() {
      if (this.vacationEditorLayout === "side_to_side") {
        return 7;
      }
      return 12;
    },

    previewWidth() {
      if (this.vacationEditorLayout === "side_to_side") {
        return 5;
      } else if (this.vacationEditorLayout === "normal") {
        return 12;
      } else {
        return 0;
      }
    },

    goToControlUsersPage() {
      return (
        process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL +
        "/users?vacation=true"
      );
    },

    validVacationData() {
      const { start_time, end_time } = this.vacation.settings;

      return (
        start_time &&
        end_time &&
        this.template_key &&
        this.currentSelectedUsers.length
      );
    },

    dateRangeText() {
      const { start_time, end_time } = this.vacation.settings;
      if (!start_time || !end_time) {
        return null;
      }
      return [start_time, end_time]
        .map((date) => this.formatDate(date))
        .join(" - ");
    },

    templateHtml() {
      return fluxTemplateToPreview(this.defaultVacationTemplate.content);
    },

    getOrUpdateUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/vacation/templates/${this.template_key}`;
    },

    isDefault() {
      return this.defaultVacationTemplate.is_default;
    },

    auth() {
      return {
        headers: {
          Authorization: this.token,
        },
      };
    },

    requiredRulesToSaveTemplate() {
      return (
        this.editorHtml.length <= this.templateLength &&
        !!this.editorHtml.length &&
        !!this.validSubjectRule &&
        this.validNameRule &&
        !this.exceededMaxTemplateContentSize
      );
    },

    descriptionRule() {
      return this.defaultVacationTemplate.description.length <= 60;
    },

    validNameRule() {
      const { name } = this.defaultVacationTemplate;
      return name.length > 0 && name.length <= this.maxNameSize;
    },

    maxNameSize() {
      return 50;
    },

    exceededMaxTemplateContentSize() {
      return this.editorHtml.length > this.templateLength;
    },

    validSubjectRule() {
      const { response_subject } = this.defaultVacationTemplate;
      return response_subject.length > 0;
    },
    editingTemplate() {
      return this.editorHtml == this.templateHtml ? false : true;
    },
  },

  async beforeMount() {
    this.setDefaultVacationSettings();
    const { vacationStart, vacationEnd } = getVacationDates();

    if (vacationStart && vacationEnd) {
      this.dates = [vacationStart, vacationEnd];
      this.confirmDatesVacation();
    }
    this.template_key = this.$router.history.current.params.id;
    if (this.template_key) {
      await this.getVacationTemplateByKey();
      this.isEditingVacationTemplate = true;
      if (this.isDefault) {
        this.redirectToVacationTemplates();
      }
    } else {
      this.defaultVacationTemplate.name = "";
      this.defaultVacationTemplate.response_subject = "";
      this.defaultVacationTemplate.description = "";
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  },
};
</script>

<style>
.error-input {
  border-radius: 5px;
  border: 2px solid red !important;
}

.template-config input:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  border: 2px solid;
}

.template-config input::placeholder {
  font-style: italic !important;
}

.template-config input {
  border-radius: 5px;
  border: 2px solid transparent;
}
</style>
